import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import socialImage from "../../static/social-share/DMITRIEVA-ART-social-share.jpg"
import SEO from "../components/seo"
import VideoHero from "../components/Home/VideoHero"
import About from "../components/Home/About"

const HomePage = ({ data, location }) => {
  const content = data.prismicHomepage.data
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const toggleTransition = state.toggleTransition
  const path = location.pathname
  const langSliced = path.slice(1, 3)
  useEffect(() => {
    if (toggleTransition) {
      setTimeout(() => {
        dispatch({ type: "TOGGLE_TRANSITION" })
      }, 600)
    }
  }, [])
  return (
    <>
      <ParallaxProvider>
        <SEO
          title={
            langSliced === "fr"
              ? "Accueil"
              : langSliced === "ru"
              ? "Домашняя страница"
              : "Home"
          }
          image={socialImage}
          lang={langSliced}
          path={path}
        />
        <VideoHero content={content} />
        <About content={content} location={location} />
      </ParallaxProvider>
    </>
  )
}

export default HomePage

export const query = graphql`
  query HomePage($lang: String!) {
    prismicHomepage(lang: { eq: $lang }) {
      lang
      data {
        photo_portrait {
          fluid {
            src
            ...GatsbyPrismicImageFluid
          }
        }
        text_bio {
          html
        }
        text_intro
        video_intro {
          url
        }
      }
    }
  }
`
