import React from "react"
import { Parallax } from "react-scroll-parallax"
import Img from "gatsby-image"

import "./styles.scss"

const About = ({ content, location }) => {
  return (
    <>
      <section className="homeabout">
        <div className="homeabout-content">
          <div className="homeabout-image">
            <Parallax y={[-20, 20]}>
              <Img
                className="homeabout-image-picture"
                fluid={content.photo_portrait.fluid}
              />
            </Parallax>
          </div>
          <div
            className="homeabout-bio"
            dangerouslySetInnerHTML={{ __html: content.text_bio.html }}
          />
        </div>
      </section>
    </>
  )
}

export default About
